.root {
  outline: none;
}

.contentWrapper {
  margin-bottom: 0;
  & > fieldset{
    @media(max-width:575px){
      padding-left:0;
      padding-right:0;
    }
  }
  @media(max-width:575px){
    margin-bottom: 5px;
  }
}

.buttonsWrapper {
  display: flex;
  padding: 0 30px 16px 30px;
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
