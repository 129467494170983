@import '../../../styles/customMediaQueries.css';

.popupSize {
  padding: 0 10px 7px 10px;
}

.filterLabel,
.filterLabelSelected,
.filterLabelWrapper {
  composes: h3 from global;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
    margin-bottom: 8px;
  }
}

.filterLabel {
  color: var(--colorBlack);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;
}

.openSign {
  float: right;
}

.clearButton {
  composes: h5 from global;
  margin: 0;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);
  padding: 2px 0 4px 12px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 4px 0 4px 12px;
  }
}

.plain {
  width: 100%;
  display: none;
}

.isOpen {
  display: block;
}

.calenderDateContainer {
  height: 370px;
  width: 310px;

  & > div {
    & > div {
      & > div {
        & > div {
          & > div {
            background-color: #fc334d;
            & > div:first-child {
              display: none;
            }
            & > div:last-child {
              margin-top: -40px !important;
              box-shadow: none !important;
              background-color: #fc334d;
              & > div {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
}

.inboxPageCalender {
  /* margin-top: -16px; */

  & :global(.DayPicker) {
    /* margin: 0px !important; */
  }

  & :global(.SingleDatePicker_picker) {
    width: 314px !important;
    /* top: 80px !important; */
    @media (max-width: 1023px) {
      /* top: 130px !important; */
    }
    /* @media(max-width:991px){
      top: 30px !important;
    } */
    @media (max-width: 767px) {
      /* top: 114px !important; */
    }
  }

  & :global(.CalendarDay) {
    /* color: var(--color-black) !important; */
    & :hover,
    & :focus {
      /* color: var(--color-black) !important; */
    }
  }
  & :global(.CalendarDay_default) {
    border-width: 0px !important;
    & :hover,
    & :focus {
      border-width: 0px !important;
    }
  }
  & :global(.SingleDatePicker_picker),
  & :global(.SingleDatePicker_picker__directionLeft),
  & :global(.DayPicker__horizontal) {
    /* background-color: var(--color-white) !important; */
    box-shadow: none !important;
    border-radius: 0 !important;
    @media (max-width: 1023px) {
      /* position: static !important; */
    }
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    /* border: 1px solid #e5e5e6; */
  }

  & :global(.CalendarMonth_caption) {
    /* color: var(--color-black); */
    font-weight: 200 !important;
  }
  & :global(.CalendarMonthGrid) {
    @media (max-width: 1023px) {
      width: 100% !important;
      height: inherit !important;
    }
  }
  & :global(.DayPickerNavigation_button) {
    /* color: var(--color-black) !important; */

    & svg {
      /* stroke: var(--color-black); */
      /* fill: var(--color-black); */
    }
  }

  & :global(.DayPicker_weekHeader) {
    /* color: var(--color-black) !important; */
    top: 54px !important;
    /* border-bottom: 1px solid #000; */
  }

  & :global(.DayPicker_weekHeader_ul) {
    border-bottom: 1px solid #fff;
    /* border-bottom: 1px solid var(--color-black); */
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }

  & :global(.CalendarDay) {
    /* color: var(--color-black) !important; */
    cursor: pointer !important;
  }
  & :global(.CalendarDay__firstDayOfWeek),
  & :global(.CalendarDay__lastDayOfWeek) {
    /* color: var(--color-blue) !important; */
  }

  & :global(.CalendarDay__outside) {
    /* color: var(--colorGrey300) !important; */
  }

  & :global(.DateInput) {
    display: none;
  }

  & :global(.CalendarDay__highlighted_calendar) {
    background-color: var(--color-black) !important;
    color: #fff !important;
    border-radius: 8px !important;

    & :hover,
    & :focus {
      background-color: var(--color-black) !important;
      color: #fff !important;
      border-radius: 8px !important;
    }
  }

  & :global(.CalendarDay__blocked_calendar) {
    background-color: var(--color-blocked) !important;
    color: #fff !important;
    border-radius: 8px !important;

    & :hover,
    & :focus {
      background-color: var(--color-blocked) !important;
      color: #fff !important;
      border-radius: 8px !important;
    }
  }
  & :global(.CalendarDay__selected) {
    background-color: #000 !important;
    border-radius: 8px !important;
    color: #fff !important;
    & :global(.renderedDay) {
      background-color: #000 !important;
      color: #fff !important;
      border-radius: 8px !important;
    }
  }

  & :global(.CalendarDay__default) {
    border: none !important;
    /* border-spacing:30px 0 !important; */
  }
  & :global(.CalendarMonth_table) {
    margin-top: 10px;
  }
}
